import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import Layout from './components/Layout/Layout';

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
  phoneNumber: '',
};

const EMAIL = 'fabiennanga.coaching@gmail.com';
const FORM_URL = 'https://formcarry.com/s/CWbuwjZLRv';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  handleFields = (e) => this.setState({
    [e.target.name]: e.target.value,
  });

  handleForm = (e) => {
    axios
      .post(FORM_URL, this.state, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        swal.fire({
          text: 'Votre message a été envoyé',
          type: 'success',
          timer: 1500,
          showConfirmButton: false,
        });

        this.setState({
          ...INITIAL_STATE,
        });
      })
      .catch((error) => {
        swal.fire({
          title: 'Une erreur est survenue',
          text: `Essayez de me contacter directement à l'adresse suivante : ${EMAIL}`,
          type: 'error',
          timer: 3000,
          showConfirmButton: false,
        });
      });

    e.preventDefault();
  };

  render() {
    return <Layout>
      <div
        id="contact"
        className="hero section is-dark pb-0"
        data-aos="fade-in"
      >
        <div className="container is-medium">
          <h1 className="title has-text-white is-with-bar is-family-hakio">
            Me contacter
          </h1>
          <p className="subtitle">
            Si vous souhaitez avoir des informations sur mes prestations,
            n'hésitez pas à me contacter
          </p>
          <form onSubmit={this.handleForm}>
            <div className="columns is-centered">
              <div className="column is-half">
                <div className="field">
                  <label className="label has-text-white" htmlFor="name">Nom</label>
                  <div className="control is-expanded">
                    <input
                      className="input is-medium"
                      id="name"
                      name="name"
                      type="text"
                      required
                      onChange={this.handleFields}
                      value={this.state.name}
                    />
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <div className="field">
                  <label className="label has-text-white" htmlFor="email">E-mail</label>
                  <div className="control is-expanded">
                    <input
                      className="input is-medium"
                      id="email"
                      name="email"
                      type="email"
                      required
                      onChange={this.handleFields}
                      value={this.state.email}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label has-text-white" htmlFor="phoneNumber">Téléphone</label>
                  <div className="control is-expanded">
                    <input
                      className="input is-medium"
                      id="phoneNumber"
                      name="phoneNumber"
                      type="tel"
                      required
                      onChange={this.handleFields}
                      value={this.state.phoneNumber}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column">
                <div className="field">
                  <label className="label has-text-white" htmlFor="message">Message</label>
                  <div className="control is-expanded">
                    <textarea
                      className="textarea is-medium"
                      id="message"
                      name="message"
                      rows="5"
                      required
                      onChange={this.handleFields}
                      value={this.state.message}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-one-third">
                <div className="field">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-light is-outlined is-medium is-fullwidth is-rounded"
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  }
}

export default Contact;